
export const AppConstants = {
    TITLE: 'Cogeco',
    LAST_REQUERT_TIME: 'LAST_REQUERT_TIME',
    TOKEN_EXP: 'token_exp',
    REFRESH_TOKEN_TIME: 'REFRESH_TOKEN_TIME',
    USER: 'USER',
    USER_NAME: 'USER_NAME',
    LANGUAGE: 'LANGUAGE',
    CheckServiceTime: 'CheckServiceTime'
}

export const DateTimeFormatConstant = {
    DATE: 'MM/DD/YYYY',
    DATE_TIME: 'MM/DD/YYYY HH:mm ss',
    UTC: 'YYYY-MM-DDTHH:mm:ss',
};
export const loginType = {
    firebase: 'firebase',
    db: 'db',
};
export const EditMode = {
    RowClick: 'RowClick',
    ClickIcon: 'ClickIcon',
};
export const languageCode = {
    EN: 'en-US',
    DE: 'de-DE',
    ES: 'es-ES',
    FR: 'fr-FR',
};
export const EmailStatus = {

};
export const DataDictionary = {
    ForceMajeure: 'Force Majeure',
    // ModemSoftware: 'ModemSoftware',
    // Actionid: 'Actionid',
    // Product: 'Product'
}
export const languages = [
    {
        text: 'English',
        value: 'en-US',
        icon: 'famfamfam-flags gb',
    },
    {
        text: 'French',
        value: 'fr-FR',
        icon: 'famfamfam-flags fr',
    },

];
export const OrderType = {
    ChangeServicePackage: 'Change Service Package',
    ClientDisconnect: 'Client Disconnect',
    ModemSwap: 'Modem Swap',
    CustomerSpecificationChange: 'Customer Specification Change',
    SoftblockAdd: 'Softblock Add',
    SoftblockRemove: 'Softblock Remove',
    TPIAOrderNewrequest: 'TPIA Order – New request for Internet Service',
    TPIAOrderCogecoInternetCustomerkeepingphone: 'TPIA Order – Cogeco Internet Customer keeping phone',
    TPIAtoTPIAtransfer: 'TPIA to TPIA transfer',
    TransferWithinISPCustomers: 'Transfer Within ISP Customers (Move to new Address)',
    TransfersCogecoInternetCustomertoISPCustomer: 'Transfers Cogeco Internet Customer to ISP Customer - Self-Install',
    SupportRequestCheckModemOnly: 'Support Request ( Check Modem Only )',
    SupportRequestTechScheduled: 'Support Request ( Tech Scheduled )',
    CustomerRelocatetoNewAddress:'Customer Relocate to New Address'
}

export const permissionConstants = {
    //--dashboard
    DASHBOARD: 'dashboard',
    //customer
    UPDATE_CUSTOMER_STATUS: 'customers.UpdateCustomerStatus',
    UPDATE_CUSTOMER_STATUS_ACTIVE: 'customers.UpdateCustomerStatus.active',
    UPDATE_CUSTOMER_STATUS_FIELDCHECK: 'customers.UpdateCustomerStatus.fieldcheck',
    UPDATE_CUSTOMER_STATUS_DISCONNECTED: 'customers.UpdateCustomerStatus.disconnected',
    UPDATE_CUSTOMER_STATUS_Prospect: 'customers.UpdateCustomerStatus.Prospect',
    UPDATE_CUSTOMER_STATUS_Suspended: 'customers.UpdateCustomerStatus.suspended',
    UPDATE_CUSTOMER_STATUS_NotServiceable: 'customers.UpdateCustomerStatus.NotServiceable',
    //UPDATE_CUSTOMER_STATUS_Pending: 'customers.UpdateCustomerStatus.Pending',
    UPDATE_CUSTOMER_STATUS_PendingDisconnect: 'customers.UpdateCustomerStatus.PendingDisconnect',
    UPDATE_CUSTOMER_STATUS_PendingActivation: 'customers.UpdateCustomerStatus.PendingActivation',
    UPDATE_CUSTOMER_STATUS_PendingModification: 'customers.UpdateCustomerStatus.PendingModification',

    CUSTOMER_NOTECATEGORY: 'customers.notecategory',
    CUSTOMER_NOTECATEGORY_DUPLICATE_TICKET: 'customers.notecategory.duplicate_ticket',
    CUSTOMER_NOTECATEGORY_COMPLETED_TRANSACTION: 'customers.notecategory.completed_transaction',
    CUSTOMER_NOTECATEGORY_COMPLETED_SUPPORT_INQUIRY: 'customers.notecategory.completed_support_inquiry',
    CUSTOMER_NOTECATEGORY_RESELLER_COMMENT: 'customers.notecategory.reseller_comment',
    CUSTOMER_NOTECATEGORY_INCOMPLETE_COMMENT: 'customers.notecategory.incomplete_comment',
    CUSTOMER_NOTECATEGORY_CLOSE_IN_ERROR: 'customers.notecategory.close_in_error',
    CUSTOMER_NOTECATEGORY_CANCELLED_SUPPORT_INQUIRY: 'customers.notecategory.cancelled_support_inquiry',
    CUSTOMER_NOTECATEGORY_CANCELLED_TRANSACTION: 'customers.notecategory.cancelled_transaction',

    CUSTOMER_DELETE: 'customers.delete',
    CUSTOMER_SEARCH: 'customers.Search',
    CUSTOMER_MODIFY: 'customers.customer_modify',
    CUSTOMER_MODIFY_ADDRESS: 'customers.customer_modify_address',
    CUSTOMER_DETAILSSUBMITNOTE: 'customers.DetailsSubmitNote',

    CUSTOMER_CREATEWORKORDER: 'customers.CreateWorkOrder',
    CUSTOMER_VIEWWORKORDERS: 'customers.ViewWorkOrders',
    CUSTOMER_STARTOVER: 'customers.StartOver',
    CUSTOMER_CHECKFORSERVICE: 'customers.CheckForService',
    CUSTOMER_ADDSUBMITNOTE: 'customers.AddSubmitNote',
    CUSTOMER_SUBMIT: 'customers.Submit',
    CUSTOMER_CSG: 'customers.csg',
    // fieldchecklists
    FIELDCHECKLISTS_SUBMIT: 'filedchecklist.Search',
    //openworkorders
    OPENWORKORDERS_SEARCH: 'openworkorders.Search',
    //searchcustomers
    SEARCHCUSTOMERS_SEARCH: 'searchcustomers.Search',
    //workorder
    WORKORDER_DELETE: 'workorder.delete',
    WORKORDER_FORCEMAJEURE: 'workorder.forcemajeure',
    WORKORDER_CREASTESUBMIT: 'workorder.CreateSubmit',
    WORKORDER_CHANGE: 'workorder.Change',
    WORKORDER_STATUS: 'workorder.Status',
    WORKORDER_STATUS_SUBMITTED: 'workorder.Status.OrderSubmitted',
    WORKORDER_STATUS_ERROR_GENERIC: 'workorder.Status.UnexpectedException',
    WORKORDER_STATUS_PENDING_SALES_ORDER_FOUND: 'workorder.Status.PendingSalesOrderFound',
    WORKORDER_STATUS_ORDER_ENTERED_IN_ERP: 'workorder.Status.OrderAcknowledged',
    WORKORDER_STATUS_SUCCESS: 'workorder.Status.Completed',
    WORKORDER_STATUS_IN_PROGRESS: 'workorder.Status.Pending',
    WORKORDER_STATUS_CANCELLED: 'workorder.Status.Cancelled',
    WORKORDER_STATUS_ADDRESS_ENTERED_DONT_EXIST: 'workorder.Status.FieldCheckRequired',
    WORKORDER_STATUS_Address_not_found: 'workorder.Status.Addressnotfound',
    WORKORDER_SUBMITNOTE: 'workorder.SubmitNote',
    WORKORDER_VIEWCOMPLETE: 'workorder.ViewCompleteCustomerDetails',
    WORKORDER_UPDATE_STATUS: 'workorder.UpdateStatus',
    WORKORDER_UPDATE_CONTENT: 'workorder.UpdateContent',
    WORKORDER_UPDATE_RESCHEDULE: 'workorder.UpdateReschedule',
    WORKORDER_UPDATE_CHANGETOTECHINSTALL: 'workorder.ChangetoTechInstall',
    WORKORDER_UPDATE_SALES_ORDER_NUMBER: 'workorder.Updatesalesordernumber',
    //roles
    SYSTEM_ROLES_ADD: 'system.roles.Add',
    SYSTEM_ROLES_SEARCH: 'system.roles.Search',
    SYSTEM_ROLES_DELETE: 'system.roles.Delete',
    SYSTEM_ROLES_SETPERMISSION: 'system.roles.SetPermission',
    //logs
    SYSTEM_LOGS_CALLINLOG: 'systems.logs.callinlog',
    SYSTEM_LOGS_CALLOUTLOG: 'systems.logs.calloutlog',
    SYSTEM_LOGS_OPERATIONLOG: 'system.logs.operationlog',
    SYSTEM_LOGS_CALLINLOG_SEARCH: 'systems.logs.callinlog.Search',
    SYSTEM_LOGS_CALLOUTLOG_SEARCH: 'systems.logs.calloutlog.Search',
    SYSTEM_LOGS_OPERATIONLOG_SEARCH: 'systems.logs.operationlog.Search',
    //language
    SYSTEM_LANGUAGE_ADD: 'system.language.Add',
    SYSTEM_LANGUAGE_SEARCH: 'system.language.Search',
    SYSTEM_LANGUAGE_DELETE: 'system.language.Delete',
    //email
    SYSTEM_EMAIL_EMAILHISTORY: 'systems.email.EmailHistory',
    SYSTEM_EMAIL_EMAILHISTORY_SEARCH: 'systems.email.EmailHistory.Search',
    SYSTEM_EMAIL_EMAILHISTORY_DELETE: 'systems.email.EmailHistory.Delete',
    SYSTEM_EMAIL_EMAILTEMPLATE: 'systems.email.EmailTemplate',
    SYSTEM_EMAIL_EMAILTEMPLATE_SEARCH: 'systems.email.EmailTemplate.Search',
    SYSTEM_EMAIL_EMAILTEMPLATE_DELETE: 'systems.email.EmailTemplate.Delete',
    SYSTEM_EMAIL_EMAILTEMPLATE_ADD: 'systems.email.EmailTemplate.Add',
    SYSTEM_EMAIL_EMAILSERVER: 'systems.email.EmailServer',
    SYSTEM_EMAIL_EMAILSERVER_SEARCH: 'systems.email.EmailServer.Search',
    SYSTEM_EMAIL_EMAILSERVER_DELETE: 'systems.email.EmailServer.Delete',
    SYSTEM_EMAIL_EMAILSERVER_ADD: 'systems.email.EmailServer.Add',
    //setting
    SYSTEM_SETTING_SETTINGS: 'systems.setting.settings',
    SYSTEM_SETTING_SETTINGS_ADD: 'systems.setting.settings.Add',
    SYSTEM_SETTING_SETTINGS_SEARCH: 'systems.setting.settings.Search',
    SYSTEM_SETTING_SETTINGS_DELETE: 'systems.setting.settings.Delete',
    SYSTEM_SETTING_DICTIONARY: 'systems.setting.dictionary',
    SYSTEM_SETTING_DICTIONARY_ADD: 'systems.setting.dictionary.Add',
    SYSTEM_SETTING_DICTIONARY_SEARCH: 'systems.setting.dictionary.Search',
    SYSTEM_SETTING_DICTIONARY_DElETE: 'systems.setting.dictionary.Delete',
    //notification
    SYSTEM_NOTIFICATION: 'systems.notification',
    SYSTEM_NOTIFICATION_CONTINUE: 'systems.notification.Continue',
    SYSTEM_NOTIFICATION_DISPALYNOW: 'systems.notification.DisplayNow',
    SYSTEM_NOTIFICATION_ACKNOWLEDGE: 'systems.notification.Acknowledge',
    SYSTEM_NOTIFICATION_SEARCH: 'systems.notification.Search',
    //configure
    SYSTEM_CONFIGURE: 'systems.configure',
    SYSTEM_CONFIGURE_BILLINGSERVICES: 'systems.configure.BillingServices',
    SYSTEM_CONFIGURE_BILLINGSERVICES_ADD: 'systems.configure.BillingServices.Add',
    SYSTEM_CONFIGURE_BILLINGSERVICES_SEARCH: 'systems.configure.BillingServices.Search',
    SYSTEM_CONFIGURE_BILLINGSERVICES_DElETE: 'systems.configure.BillingServices.Delete',
    SYSTEM_CONFIGURE_NOTECATEGORY: 'systems.configure.NoteCategory',
    SYSTEM_CONFIGURE_NOTECATEGORY_ADD: 'systems.configure.NoteCategory.Add',
    SYSTEM_CONFIGURE_NOTECATEGORY_SEARCH: 'systems.configure.NoteCategory.Search',
    SYSTEM_CONFIGURE_NOTECATEGORY_DElETE: 'systems.configure.NoteCategory.Delete',
    SYSTEM_CONFIGURE_PACKAGE: 'systems.configure.Package',
    SYSTEM_CONFIGURE_PACKAGE_ADD: 'systems.configure.Package.Add',
    SYSTEM_CONFIGURE_PACKAGE_SEARCH: 'systems.configure.Package.Search',
    SYSTEM_CONFIGURE_PACKAGE_DElETE: 'systems.configure.Package.Delete',
    //--user
    SYSTEM_USER: 'systems.users',
    SYSTEM_USER_ADD: 'systems.users.add',
    SYSTEM_USER_EDIT: 'systems.users.edit',
    SYSTEM_USER_DELETE: 'systems.users.delete',
    SYSTEM_USER_EXPORT: 'systems.users.export',
    SYSTEM_USER_UPLOAD: 'systems.users.upload',
    //TPIA
    MANAGEMENT_TPIAS: 'management.ISP',
    MANAGEMENT_TPIAS_ADD: 'management.ISP.Add',
    MANAGEMENT_TPIAS_SEARCH: 'management.ISP.Search',
    MANAGEMENT_TPIAS_DELETE: 'management.ISP.Delete',
    //PortalNotices
    MANAGEMENT_PORTALNOTICES: 'management.PortalNotices',
    MANAGEMENT_PORTALNOTICES_ADD: 'management.PortalNotices.Add',
    MANAGEMENT_PORTALNOTICES_DELETE: 'management.PortalNotices.Delete',
    //ORDERTYPE
    MANAGEMENT_ORDERTYPE: 'management.OrderTypes',
    MANAGEMENT_ORDERTYPE_Add: 'management.OrderTypes.Add',
    //PRODUCT
    MANAGEMENT_PRODUCT: 'management.Products',
    MANAGEMENT_PRODUCT_ADD: 'management.Products.Add',
    //MODEMS
    MANAGEMENT_MODEMS: 'management.certifiedmodems',
    MANAGEMENT_MODEMS_ADD: 'management.certifiedmodems.Add',
    //COMMUNICATION
    MANAGEMENT_COMMUNICATION: 'management.Communication',
    MANAGEMENT_COMMUNICATION_ADD: 'management.Communication.Add',
    MANAGEMENT_COMMUNICATION_DELETE: 'management.Communication.Delete',
    //BULK IMPORT
    MANAGEMENT_BULKIMPORT: 'management.bulkimport',
    //Portal Users
    MANAGEMENT_PORTALUSERS: 'management.PortalUsers',
    MANAGEMENT_PORTALUSERS_ADD: 'management.PortalUsers.Add',
    MANAGEMENT_PORTALUSERS_DELETE: 'management.PortalUsers.Delete',
    MANAGEMENT_RESUBMIT: 'management.resubmit',
}
